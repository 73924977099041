@import 'styles/variables';

.container {
  overflow: hidden;

  border-radius: 6px;
  background-color: $white;
  outline: 2px solid rgba(255, 255, 255, 0.20);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.05), 0px 5px 10px 0px rgba(0, 0, 0, 0.05), 0px 18px 18px 0px rgba(0, 0, 0, 0.04), 0px 42px 25px 0px rgba(0, 0, 0, 0.03), 0px 74px 30px 0px rgba(0, 0, 0, 0.01), 0px 116px 32px 0px rgba(0, 0, 0, 0.00);

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  padding: 5px 12px;
  background-color: $gray-light-color;

  color: $text-black;
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.headerCloseButton {
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity .1s ease-in;
  opacity: .4;
  &:hover {
    opacity: 1;
  }
}

/* Animations */

.container[data-state='open'][data-side='top'] {
  animation-name: slideDownAndFade;
}
.container[data-state='open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}
.container[data-state='open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.container[data-state='open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}