@import 'styles/variables';

.container {
  padding: 8px 12px;
  background-color: $bg-fifth;

  margin-top: -12px;
  border-radius: 6px 6px 0 0;
}

.crumb {
  cursor: pointer;
  text-transform: uppercase;
  color: $white-40;
  font-size: 11px;
  line-height: 14px;
  font-weight: 700;
  transition: color .1s ease-in;

  &:hover {
    color: $white-80;
  }

  &:last-child {
    pointer-events: none;
  }
}

.crumb+.crumb::before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  margin: 0 2px;

  opacity: .4;
  background-image: url("../../../public/img/arrow-gray.svg");
  background-repeat: no-repeat;
  background-position: center center;
}