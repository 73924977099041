@import 'styles/variables';

.container {
  position: fixed;
  left: 50%;
  bottom: 0;

  max-width: 90vw;
  max-height: 85vh;
  min-height: 180px;

  border-radius: 16px 16px 0 0;
  background-color: $white;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.05), 0px 5px 10px 0px rgba(0, 0, 0, 0.05), 0px 18px 18px 0px rgba(0, 0, 0, 0.04), 0px 42px 25px 0px rgba(0, 0, 0, 0.03), 0px 74px 30px 0px rgba(0, 0, 0, 0.01), 0px 116px 32px 0px rgba(0, 0, 0, 0.00);

  transform: translate(-50%, 0);

  animation: contentShow 200ms cubic-bezier(0.16, 1, 0.3, 1);

  &::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 6px;

    top: -4px;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);

    border-radius: 10px;
    background-color: $white-30;
  }
}

.overlay {
  position: fixed;
  inset: 0;
  pointer-events: all;

  background-color: rgba(0,0,0, .6);
  animation: overlayShow 200ms cubic-bezier(0.16, 1, 0.3, 1);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  padding: 5px 12px;
  background-color: $gray-light-color;
  border-radius: inherit;

  color: $text-black;
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.headerCloseButton {
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity .1s ease-in;
  opacity: .4;
  &:hover {
    opacity: 1;
  }
}

/* Animations */

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, 100%) scale(0.90);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0) scale(1);
  }
}