@import 'styles/variables';

.container {
  padding: 12px 12px 40px 12px;
  border-radius: 6px;
  background-color: $gray-secondary-color;

  @media screen and (max-width: 768px) {
    padding: 0 0 12px;
    border-radius: 0;
  }
}