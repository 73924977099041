@import 'styles/variables';

$line-dashed-background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='744' height='2' viewBox='0 0 744 2' fill='none'%3E%3Cpath opacity='0.4' d='M0 1L744 1.00007' stroke='white' stroke-dasharray='3 5'/%3E%3C/svg%3E");

.grid {
    display: grid;
    grid-template-columns: 1fr repeat(6, 23px) 100px;
    grid-column-gap: 16px;
    align-items: center;

    & > :nth-child(6) {
        display: none;
    }
}

.gridHeaderLabel {
    color: $white;
    font-size: 13px;
    text-align: center;
    opacity: .6;
}
.gridHeaderLabel:first-child {
    text-align: left;
}

.team {
    position: relative;
    padding: 10px 12px;
    border-radius: 6px;

    color: $white;
    font-size: 13px;

    &:nth-child(even) {
        background-color: $gray-color;
    }

    &.lineTop::before {
        content: '';
        position: absolute;
        height: 1px;

        top: -1px;
        left: 12px;
        right: 12px;

        background-position-x: -1px;
        background-image: $line-dashed-background;
    }

    &.lineBottom::after {
        content: '';
        position: absolute;
        height: 1px;

        bottom: -1px;
        left: 12px;
        right: 12px;

        background-position-x: -1px;
        background-image: $line-dashed-background;
    }
}

.teamContainer {
    display: grid;
    align-items: center;
    grid-template-columns: max-content max-content minmax(0, 1fr);
    grid-column-gap: 8px;
}

.teamPosition {
    position: relative;
    height: 26px;
    width: 26px;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 13px;
    font-weight: 700;

    border-radius: 50px;
    background: $gray-third;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
}

.matchesResults {
    display: flex;
    justify-content: flex-end;

    & > *:not(:first-child) {
        margin-left: -4px;
    }
}

.matchLogo {
    width: 20px;
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    font-size: 10px;
    color: $text-black;

    border-radius: 50%;
    border: 1px solid $gray-color;
    background-color: $gray-light-second;

    &.colorWin {
        color: $white;
        background-color: $green;
    }

    &.colorLose {
        color: $white;
        background-color: $red-secondary;
    }

    &.nextMatch {
        background-color: $white;
    }
}

.labelWithSlash+.labelWithSlash:before {
    content: ' / ';
    opacity: .4;
}

/* Adaptive */

.container {
    &.isWidthBelow580 {
        .grid {
            grid-template-columns: minmax(61px, 1fr) 23px 66px 23px;

            & > :nth-child(3),
            & > :nth-child(4),
            & > :nth-child(5),
            & > :nth-child(7),
            & > :nth-child(9),{
                display: none;
            }

            & > :nth-child(6) {
                display: initial;
            }
        }
    }

    &.isWidthBelow371 {
        .grid {
            grid-column-gap: 8px;
        }
    }
}