.skeletonHeaderContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-grow: 1;
  margin-bottom: 12px;

  @media screen and (max-width: 768px) {
    padding: 12px;
    margin-bottom: 0;
  }
}
.skeletonHeaderRowSecondGrid {
  display: grid;
  grid-template-columns: 60px 60px 60px 45px 83px;
  grid-column-gap: 24px;
  align-items: center;

  min-height: 50px;

  @media screen and (max-width: 440px) {
    & > :nth-child(4), :nth-child(5) {
      display: none;
    }
  }
}

.headerNoCompData {
  margin-bottom: 12px;

  display: flex;
  flex-flow: row;
  gap: 4px;
  padding: 4px 8px;
  align-self: flex-start;

  border-radius: 6px;
  background-color: rgba(#fff, .05);

  font-size: 13px;
  line-height: 16px;
  color: rgba(#fff, .6);

  @media screen and (max-width: 768px) {
   margin: 0 12px 12px 12px;
  }
}