@import 'styles/variables';

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
  padding: 7px;
  background-color: $gray-color;
  border-radius: 0 0 6px 6px;

  @media screen and (max-width: 768px) {
    height: unset;
    padding: 12px;
  }
}

.button {
  flex-grow: 1;

  color: $white;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    padding: 12px;
    border-radius: 6px;
    background-color: rgba($white, .05);
  }
}