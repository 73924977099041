.countryBlock {
  height: 40px;
  padding: 10px 8px;

  font-size: 13px;

  border-radius: 6px;
  background-color: #f0f0f2;
}

.competition {
  min-height: 40px;
  padding: 8px 8px 8px 36px;

  font-size: 13px;
  color: #171e2199;
  border-radius: 6px;

  cursor: pointer;
  transition: background-color .1s ease-in;

  &:hover {
    background-color: #eceded;
  }

  &.active {
    pointer-events: none;
    font-weight: 700;
    color: #171e21;
  }

  &.withIcon {
    padding: 8px;
    gap: 8px;
  }
}