@import 'styles/variables';

.header {
  padding-top: 16px;
  padding-bottom: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
.headerLabel {
  color: $black-40;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
}

.container {
  display: flex;
  flex-flow: column;
  gap: 4px;
}
.containerNotFound {
  height: 168px;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 28px;

  background-color: $gray-light-color;
  border-radius: 6px;

  color: $black-40;
  font-size: 13px;
  line-height: 16px;
}

.team {
  display: flex;
  align-items: center;
  gap: 16px;

  color: $text-black;
  font-size: 15px;
  line-height: 18px;

  border-radius: 6px;
  border: 1px solid #e6e6e6;
}

.teamPositionContainer {
  padding: 4px 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  gap: 4px;

  border-radius: 6px 0 0 6px;
  background-color: $gray-light-color;

  &.isGroupSelected .teamPosition {
    color: $black-40;
  }
}
.teamPosition {
  width: 38px;
}
.positionDivider {
  width: 1px;
  height: 100%;
  background-color: #d9d9d9;
}
.teamIndex {
  position: relative;

  width: 32px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    width: 12px;
    height: 2px;

    border-radius: 2px;
    background-color: $yellow-hover;
  }
}

.teamName {
  color: $text-black;
  font-weight: 400;
}

.teamPoints {
  overflow: hidden;

  padding: 14px 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  font-weight: bold;

  border-radius: 0 6px 6px 0;
  background-color: $gray-light-color;
}
.teamPointsWidth {
  width: 74px;
}