@import 'styles/variables';

.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 16px;
  padding: 16px;
  background-color: #2a3446;
}

.title {
  color: $white;
  font-size: 56px;
  font-weight: bold;
}

.description {
  color: $white;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.subtitle {
  color: $white-40;
}

.button {
  min-height: 40px;
  padding: 8px;
  border-radius: 6px;

  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  text-transform: uppercase;

  transition: background-color .2s ease-in;
  &:hover {
    background-color: $secondary-color;
  }
}
.buttonIcon {
  transform: rotate(90deg);
}