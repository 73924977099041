.sectionWrapper.sectionWrapper {
    max-width: 768px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 12px;
    margin-top: 40px;
}