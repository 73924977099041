@import 'styles/variables';

.header {
  padding: 8px;

  display: flex;
  align-items: center;
  gap: 8px;

  border-radius: 6px;
  background: $gradient-black;

  color: $white;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

.headerLogo {
  padding: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #fff;
  border-radius: 50%;
}

.tabsContainer {
  margin-top: 8px;

  display: flex;
  flex-flow: row wrap;
  gap: 4px;
}

.searchContainer {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  margin-top: 8px;
  padding: 8px;

  border: 1px solid #e6e6e6;
  border-radius: 6px;

  input {
    flex-grow: 1;

    font-size: 13px;
    line-height: 16px;

    color: $text-black;
    border: none;

    &::placeholder {
      /* Most modern browsers support this now. */
      color: $black-40;
    }
  }

  &.isActive {
    .iconSearch path {
      fill-opacity: 1;
    }
  }

}

.iconSearch, .iconReset {
  path {
    transition: fill-opacity .1s ease-in;
  }
}

.buttonReset {
  &:disabled {
    pointer-events: none;
  }

  &:hover .iconReset path {
    fill-opacity: 1;
  }
}
