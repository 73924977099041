@import 'styles/variables';

.container {
  display: flex;
  flex-flow: row wrap;
  column-gap: 4px;
  row-gap: 8px;
  grid-column: 1;

  padding: 11px 0 12px;

  @media screen and (max-width: 768px) {
    padding: 12px;
  }
}

.badge {
  padding: 5px 10px;
  background-color: $gray-third;

  color: $white;
  font-size: 13px;
  font-weight: bold;

  border-radius: 46px;
  border-width: 1px;
  border-style: solid;
}