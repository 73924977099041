@import "styles/variables";

$card-height: 72px;
$cards-gap: 8px;
$connector-height: 2px;

.grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 257px));

    //@todo Fix playoff stacking
    margin: 0 -142px;
    @media screen and (max-width: 1310px) {
        margin: 0 calc((100vw - 30px - 208px - 12px - 746px) * -0.5);
    }
    @media screen and (max-width: 1018px) {
        margin: 0 -12px;
    }

    &.stages_3 {
        grid-template-columns: repeat(3, minmax(0, 257px));
        justify-content: center;

        .column {
            min-height: 736px;

            &:first-child, &:last-child {
                padding-right: 16px;
                padding-left: 16px;
            }
        }
    }

    &.stages_2 {
        grid-template-columns: repeat(2, minmax(0, 372px));
        justify-content: center;

        .column {
            min-height: 440px;

            &:first-child, &:last-child {
                padding-right: 16px;
                padding-left: 16px;
            }
        }

        .card {
            max-width: 224px;
        }
    }
}

.column {
    position: relative;
    padding: 64px 16px 24px 16px;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    gap: $cards-gap;

    background-color: rgba(0, 0, 0, 0.25);
}
.column:first-child {
    border-radius: 6px 0 0 6px;
    padding-left: 32px;
}
.column:last-child {
    border-radius: 0 6px 6px 0;
    padding-right: 32px;
}
.column:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.30);
}

.columnTitle {
    position: absolute;
    top: 24px;
    left: 0;
    right: 0;

    color: #ffffff66;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}

.card {
    position: relative;
    height: 74px;
    width: 100%;
    padding: 8px;

    display: flex;
    gap: 8px;

    border-radius: 6px;
    background-color: #434f65;
}

.teamTitleContainer {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
}

.teamTitle {
    color: $white-40;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.isWinner {
        color: $white;
    }
}

.scoreBox {
    position: relative;

    display: flex;
    flex-flow: column;
    justify-content: center;
    gap: 8px;
    padding: 8px;

    color: #fff;
    font-size: 13px;
    font-weight: 700;
    text-align: center;

    border-radius: 6px;
    background-color: #232a38;
    transition: background-color .2s ease-in;

    &.hover {
        &:hover {
            background-color: #1c222d;
        }
    }
}
.score {
    width: 14px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Live pair */

.card.live {
    box-shadow: -2px 0 0 0 #ff4848;
}

.scoreBox.live {
    background-color: #ff4848;

    &:hover {
        background-color: #ef2a2a;
    }
}

/* Card thumbs */

.teamLogoThumb {
    width: 26px;
    height: 26px;
    flex-shrink: 0;

    border-radius: 50%;
    background-color: $gray-color;
}

.teamTitleThumb {
    width: 100%;
    max-width: 100px;
    height: 10px;

    border-radius: 2px;
    background-color: $gray-color;
}

/* Cards connectors */

.connector {
    position: absolute;
    width: 4px;
    height: 4px;
    top: 50%;


    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 0 2px #ffffff57;

    &In {
        left: 0;
        transform: translateY(-50%);
    }

    &Out {
        right: 0;
        transform: translateY(-25%);
    }
}

.card:nth-child(odd) .connectorOut {
    top: unset;
    right: 0;
    bottom: 50%;
    transform: translateY(25%);
}

.column {
    .card::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 26px;
        height: $connector-height;
        transform: translate(-100%, -50%);
        background-color: #ffffff26;
    }
}

.columnStage_8 {
    .card {
        &::before {
            display: none;
        }

        .connectorIn {
            display: none;
        }

        &:nth-child(even) {
            &::after {
                content: '';
                position: absolute;
                width: 6px;
                height: calc(50% + ($cards-gap * 0.5) - $connector-height - ($connector-height * 0.5));
                top: 50%;
                right: 0;
                transform: translateX(100%);
                z-index: 1;
                border-top: $connector-height solid #ffffff26;
                border-right: $connector-height solid #ffffff26;
                border-top-right-radius: 50px;
            }
        }
        &:nth-child(odd) {
            &::after {
                content: '';
                position: absolute;
                width: 6px;
                height: calc(50% + ($cards-gap * 0.5) - $connector-height - ($connector-height * 0.5));
                bottom: 50%;
                right: 0;
                transform: translateX(100%);
                z-index: 1;
                border-bottom: 2px solid #ffffff26;
                border-right: 2px solid #ffffff26;
                border-bottom-right-radius: 50px;
            }
        }
    }
}
.columnStage_4 {
    .card:nth-child(even) {
        &::after {
            content: '';
            position: absolute;
            width: 6px;
            height: calc((50% * 2) + (($cards-gap * 2) * 0.5) - $connector-height - ($connector-height * 0.5));
            top: 50%;
            right: 0;
            transform: translateX(100%);
            z-index: 1;
            border-top: 2px solid #ffffff26;
            border-right: 2px solid #ffffff26;
            border-top-right-radius: 50px;
        }
    }
    .card:nth-child(odd) {
        &::after {
            content: '';
            position: absolute;
            width: 6px;
            height: calc((50% * 2) + (($cards-gap * 2) * 0.5) - $connector-height - ($connector-height * 0.5));
            bottom: 50%;
            right: 0;
            transform: translateX(100%);
            z-index: 1;
            border-bottom: 2px solid #ffffff26;
            border-right: 2px solid #ffffff26;
            border-bottom-right-radius: 50px;
        }
    }
}
.columnStage_2 {
    .card:nth-child(even) {
        &::after {
            content: '';
            position: absolute;
            width: 6px;
            height: calc((50% * 4) + (($cards-gap * 4) * 0.5) - $connector-height - ($connector-height * 0.5));
            top: 50%;
            right: 0;
            transform: translateX(100%);
            z-index: 1;
            border-top: 2px solid #ffffff26;
            border-right: 2px solid #ffffff26;
            border-top-right-radius: 50px;
        }
    }
    .card:nth-child(odd) {
        &::after {
            content: '';
            position: absolute;
            width: 6px;
            height: calc((50% * 4) + (($cards-gap * 4) * 0.5) - $connector-height - ($connector-height * 0.5));
            bottom: 50%;
            right: 0;
            transform: translateX(100%);
            z-index: 1;
            border-bottom: 2px solid #ffffff26;
            border-right: 2px solid #ffffff26;
            border-bottom-right-radius: 50px;
        }
    }
}
.columnStage_1 {
    .card .connectorOut {
        display: none;
    }
}

.grid.stages_3 {
    .columnStage_4 .card {
        &::before {
            display: none;
        }
        .connectorIn {
            display: none;
        }
    }

    .pairThirdPlace {
        bottom: 88px;
    }
}

.grid.stages_2 {
    .columnStage_2 .card {
        &:before {
            display: none;
        }
        .connectorIn {
            display: none;
        }
        &:after {
            height: 87px;
        }
    }

    .columnStage_1 .card:before {
        width: 142px;
    }

    .pairThirdPlace {
        bottom: 24px;
    }
}

/* Third place pair */

.pairThirdPlace {
    position: absolute;
    bottom: 60px;
    left: 16px;
    right: 16px;

    display: flex;
    flex-flow: column;
    align-items: center;

    .columnTitle {
        position: relative;
        display: block;
        top: 0;
        width: 100%;
    }

    .card {
        margin-top: 8px;
        &::before, .connector {
            display: none;
        }
    }
}