@import 'styles/variables';

.dateStart {
  width: 40px;
  opacity: .4;
}

.teamScore {
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
}

.coeffBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  padding: 4px;
  border: 1px solid rgba(#fff, 0.08);
  border-radius: 6px;

  color: $white;
  font-weight: bold;

  transition-property: color, background-color;
  transition-duration: .1s;
  transition-timing-function: ease-in;

  &:hover {
    color: $text-black;
    background-color: $primary-color;
  }
}

.isMatchFinished {
  color: $white-40;
  font-weight: initial;
  pointer-events: none;
}

.isWinner {
  font-weight: bold;
}