@import 'styles/variables';

// Animation should last about 10s
$iterations-count: 10;

.animationRoot {
  --no-animate: none;
}

.animationFlash {
  animation: var(--no-animate, flash ease-out 1s 0s $iterations-count);
}

@keyframes flash {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.animationPulse {
  animation: var(--no-animate, pulse ease-out .8s 0s $iterations-count);
}

@keyframes pulse {
  0% { transform: scale(1); }
  10% { transform: scale(1.4); }
  100% { transform: scale(1); }
}

.animationMatchScore {
  position: relative;
  animation: var(--no-animate, matchScoreMain linear 1s 0s $iterations-count);

  &::before {
    content: attr(title);
    position: absolute;

    opacity: 0;
    color: $bg-fifth;
    // Text stroke hack (from https://owumaro.github.io/text-stroke-generator/)
    text-shadow: $white 2px 0 0, $white 1.75517px 0.958851px 0, $white 1.0806px 1.68294px 0, $white 0.141474px 1.99499px 0, $white -0.832294px 1.81859px 0, $white -1.60229px 1.19694px 0, $white -1.97998px 0.28224px 0, $white -1.87291px -0.701566px 0, $white -1.30729px -1.5136px 0, $white -0.421592px -1.95506px 0, $white 0.567324px -1.91785px 0, $white 1.41734px -1.41108px 0, $white 1.92034px -0.558831px 0;

    animation: var(--no-animate, matchScoreMainAdditional linear 1s 0s $iterations-count);
  }
}

@keyframes matchScoreMain {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.3);

  }
  100% {
    transform: scale(1);
  }
}

@keyframes matchScoreMainAdditional {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

