@import 'styles/variables';

.content {
  padding: 10px;

  color: $white;
  font-size: 13px;

  border-radius: 6px;
  background-color: $bg-fifth;
  border: 2px solid rgba(255, 255, 255, 0.10);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.05), 0 5px 10px 0 rgba(0, 0, 0, 0.05), 0 18px 18px 0 rgba(0, 0, 0, 0.04), 0 42px 25px 0 rgba(0, 0, 0, 0.03), 0 74px 30px 0 rgba(0, 0, 0, 0.01), 0 116px 32px 0 rgba(0, 0, 0, 0.00);
}