@import 'styles/variables';

.matchesResults {
  display: flex;

  & > *:not(:first-child) {
    margin-left: -6px;
  }
}

.matchLogo {
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  font-size: 10px;
  color: $text-black;
  font-weight: bold;

  border-radius: 50%;
  border: 2px solid $white;
  background-color: $gray-light-second;

  &.colorWin {
    color: $white;
    background-color: $green;
  }

  &.colorLose {
    color: $white;
    background-color: $red-secondary;
  }

  &.nextMatch {
    background-color: $white;
  }

  @media screen and (max-width: 400px) {
    display: none;
  }
}