/* Colors */

$primary-color: #ffdc21;
$complementary-color: #027b5b;
$secondary-color: #f1d74a;
$transparent-color: #ffffff99;
$gray-color: #394356;
$gray-secondary-color: #434f65;
$gray-third: #232a38;
$gray-light-color: #f0f0f0;
$gray-light-second: #dfdfdf;
$gray-complementary-color: #d0d0d7;

$stroke_hover: #e6e6e6;

$white: #fff;
$white-80: rgba(255, 255, 255, 0.8);
$white-60: rgba(255, 255, 255, 0.6);
$white-40: rgba(255, 255, 255, 0.4);
$white-30: rgba(255, 255, 255, 0.3);
$white-20: rgba(255, 255, 255, 0.2);

$text-black: #171e21;
$black-60: rgba(23, 30, 33, 0.6);
$black-40: rgba(23, 30, 33, 0.4);
$black-20: rgba(23, 30, 33, 0.2);

$container_shadow-main: #f5f5f5;

$yellow-hover: #ffc700;

$green: #0e8d5f;

$primary-red-color: #fb4242;
$red-secondary: #ff4848;

$bg-fifth: #232a38;
$bg-sixth: #1c222d;

// Gradients

$gradient-black: linear-gradient(to right, #45526d 0%, #252e3f 100%);

// Shadows

$dropdown-shadow: 0 74px 30px rgb(0 0 0 / 1%), 0 42px 25px rgb(0 0 0 / 3%), 0 18px 18px rgb(0 0 0 / 4%), 0 5px 10px rgb(0 0 0 / 5%), 0 0 0 rgb(0 0 0 / 5%);

/* Breakpoints */

$tablet: 575px;
$mobile: 359px;

$l: 1280px;
$safe-zone: 30px;

// === Breakpoints === //

$desktop: 1042px;
$tablets: 768px;
$phone-large: 540px;
$phone-middle: 480px;
$phone-small: 420px;
$phone-extra-small: 350px;

// === Spacing === //

$space-2: 2px;
$space-4: 4px;
$space-8: 8px;
$space-12: 12px;
$space-16: 16px;
$space-24: 24px;
$space-32: 32px;
$space-64: 64px;
$space-128: 128px;
