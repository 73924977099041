@import "styles/variables";

$card-height: 72px;
$columns-gap: 18px;
$cards-gap: 18px;
$cards-gap-mobile: 4px;
$connector-height: 2px;


.grid {
  --grid-padding-x: 12px;
  --container-width-max: 100vw;
  --card-width: 74px;

  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-row-gap: $columns-gap;

  padding: 16px var(--grid-padding-x);
  background-color: #323b4c;
  border-radius: 6px;

  margin: 0 -12px;
}

.column {
  --cards-gap: calc((var(--container-width-max) - (var(--grid-padding-x) * 2) - (var(--card-width) * 4)) / 4);

  position: relative;

  display: flex;
  justify-content: space-around;
}

.columnTitle {
  position: absolute;

  color: #ffffff66;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
  text-align: center;
}

.card {
  position: relative;

  width: var(--card-width);
  padding: 4px;

  display: flex;
  flex-flow: column;

  border-radius: 6px;
  background-color: #434f65;
}

.teamsContainer {
  width: 31px;
}

.teamTitle {
  color: $white-40;
  font-size: 13px;
  font-weight: 700;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.isWinner {
    color: $white;
  }
}

.scoreBox {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 7px;

  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-align: center;

  border-radius: 6px;
  background-color: $gray-third;

  margin-top: auto;

  &.first {
    border-radius: 6px 6px 0 0;
  }
  &.second {
    border-radius: 0 0 6px 6px;
  }

  &.hover {
    &:hover {
      background-color: #1c222d;
    }
  }
}
.divider {
  height: 1px;
  width: 100%;
  background-color: $gray-color;
}
.score {
  width: 20px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.iconContainer {
  position: absolute;
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: #1c222d;
  box-shadow: 1px 1px 0 2px $gray-secondary-color;

  top: 2px;
  left: 2px;
}

/* Card statuses */

.card.live {
  box-shadow: -2px 0 0 0 #ff4848;
}

.scoreBox.live {
  background-color: #ff4848;

  &:hover {
    background-color: #ef2a2a;
  }
}

/* Card thumbs */

.teamLogoThumb {
  width: 26px;
  height: 26px;
  flex-shrink: 0;

  border-radius: 50%;
  background-color: $gray-color;
}

.teamTitleThumb {
  width: 100%;
  height: 10px;

  border-radius: 2px;
  background-color: $gray-color;
}

/* Cards connectors */

.connector {
  position: absolute;
  width: 4px;
  height: 4px;
  left: 50%;

  border-radius: 50%;
  opacity: .5;
  background-color: #fff;
  box-shadow: 0 0 0 2px #ffffff57;

  &In {
    top: 0;
    transform: translate(-50%, -50%);
  }

  &Out {
    bottom: 0;
    transform: translate(-50%, 50%);
  }
}

.column {
  .card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 12px;
    width: $connector-height;
    transform: translate(-50%, -100%);
    background-color: #ffffff26;
  }
}

.column:first-child {
  .card {
    &::before {
      display: none;
    }

    .connectorIn {
      display: none;
    }
  }
}

.column:last-child {
  .card {
    .connectorOut {
      display: none;
    }

    &::before {
      display: none;
    }
  }
}

.rowPairs_4 {
  .columnTitle {
    display: none;
  }

  .card {
    &::after {
      content: '';
      position: absolute;
      height: 6px;
      width: calc(50% + (var(--cards-gap) * 0.5) - $connector-height);
      z-index: 1;
    }
  }
}
.rowPairs_2 {
  .columnTitle {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .card:nth-child(even) {
    &::after {
      content: '';
      position: absolute;
      height: 6px;
      width: calc((50% * 2) + ((var(--cards-gap) * 2) * 0.5) - $connector-height);
      z-index: 1;
    }
  }

  .card:nth-child(odd) {
    &::after {
      content: '';
      position: absolute;
      height: 6px;
      width: calc((50% * 2) + ((var(--cards-gap) * 2) * 0.5) - $connector-height);
      z-index: 1;
    }
  }
}
.rowPairs_1 {
  .columnTitle {
    top: 50%;
    right: 50%;
    transform: translate(-60px, -50%);
  }
}

/* Config connectors between pairs */

// Connection down
.rowConnectorsDown {
  .card {
    &:nth-child(even) {
      &::after {
        right: 50%;
        bottom: 0;
        transform: translate(1px, 100%);
        border-right: $connector-height solid #ffffff26;
        border-bottom: $connector-height solid #ffffff26;
        border-bottom-right-radius: 50px;
      }
    }

    &:nth-child(odd) {
      &::after {
        left: 50%;
        bottom: 0;
        transform: translate(-1px, 100%);
        border-bottom: $connector-height solid #ffffff26;
        border-left: $connector-height solid #ffffff26;
        border-bottom-left-radius: 50px;
      }
    }
  }
}

// Connection up
.rowConnectorsUp {
  .card {
    &::before {
      top: unset;
      bottom: 0;
      transform: translate(-50%, 100%);
    }

    &:nth-child(even) {
      &::after {
        top: 0;
        right: 50%;
        transform: translate(1px, -100%);
        border-top: $connector-height solid #ffffff26;
        border-right: $connector-height solid #ffffff26;
        border-top-right-radius: 50px;
      }
    }

    &:nth-child(odd) {
      &::after {
        top: 0;
        left: 50%;
        transform: translate(-1px, -100%);
        border-top: $connector-height solid #ffffff26;
        border-left: $connector-height solid #ffffff26;
        border-top-left-radius: 50px;
      }
    }
  }
}

// Additional config for middle (Winner) pair
.rowMiddle {
  .card {
    &::before {
      height: $columns-gap;
    }

    .connectorOut::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      height: $columns-gap;
      width: $connector-height;
      transform: translate(-50%, 100%);
      background-color: #ffffff26;
    }

    // Display win border and icon for the first place
    &:first-child {
      box-shadow: 0 0 0 2px white;

      // Sync connectors with shadow
      .connectorIn {
        transform: translate(-50%, calc(-50% - 1px));
      }
      .connectorOut {
        transform: translate(-50%, calc(50% + 1px));
      }

      // Display winner icon
      &::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;

        left: 0;
        top: 0;
        transform: translate(-50%, -50%);

        background-color: $white;
        border-radius: 50%;

        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.66672 7.77778H5.11111V7.09813L4.36706 6.29453L2.86587 5.71715C2.60102 5.61529 2.40072 5.39309 2.32679 5.11913L2.03524 4.03884C1.87994 3.46725 2.25734 2.88856 2.78542 2.88856H3.33773C3.37919 2.46277 3.7094 2 4.11854 2H7.88122C8.29036 2 8.62074 2.46277 8.66226 2.88856H9.21545C9.74352 2.88856 10.1209 3.46725 9.96563 4.03884L9.67408 5.11913C9.60015 5.39309 9.39985 5.61529 9.135 5.71715L7.63252 6.29502L6.88889 7.09817V7.77778H7.33312C7.57851 7.77778 7.77749 7.97665 7.77759 8.22204C7.7777 8.46757 7.57868 8.66667 7.33315 8.66666L4.66664 8.66663C4.4212 8.66663 4.22222 8.46765 4.22222 8.22221C4.22222 7.97676 4.42127 7.77778 4.66672 7.77778ZM8.38412 5.25095C8.38268 5.26531 8.38085 5.2796 8.37863 5.2938L8.89569 5.09493C8.9619 5.06947 9.01197 5.01392 9.03046 4.94543L9.322 3.86514L9.3223 3.86405C9.34901 3.76571 9.32769 3.67376 9.28841 3.61352C9.25034 3.55515 9.21959 3.55521 9.21557 3.55521L9.21545 3.55522H8.59959L8.38412 5.25095ZM3.40042 3.55522H2.78542L2.7853 3.55521C2.78127 3.55521 2.75053 3.55515 2.71246 3.61352C2.67318 3.67376 2.65185 3.76571 2.67857 3.86405L2.67887 3.86514L2.97041 4.94543C2.9889 5.01392 3.03897 5.06947 3.10518 5.09493L3.62132 5.29345C3.61913 5.27935 3.61731 5.26517 3.61588 5.25091L3.40042 3.55522ZM4.22228 9.11122C3.97682 9.11122 3.77784 9.3102 3.77784 9.55566C3.77784 9.80111 3.97682 10.0001 4.22228 10.0001H7.77775C8.02321 10.0001 8.22219 9.80111 8.22219 9.55566C8.22219 9.3102 8.02321 9.11122 7.77775 9.11122H4.22228Z' fill='%23394356'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
      }
      .iconContainer {
        display: none;
      }
    }
  }
}



/* Third place pair */

.pairThirdPlace {
  position: absolute;
  left: 50%;

  display: flex;
  flex-flow: column;
  align-items: center;

  transform: translate(100%, 50%);

  .columnTitle {
    position: relative;
    top: unset;
    right: unset;
    transform: unset;
  }

  .card {
    margin-top: 4px;

    &::before, .connector {
      display: none;
    }
  }
}

/* Adaptive */

// Styles for screen max-width: 420px

.grid.isSmall {
  --grid-padding-x: 6px;

  .column .columnTitle {
    display: none;
  }
}