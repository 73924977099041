@import 'styles/variables';

.container {
  display: flex;
  flex-flow: column;
  gap: 16px;
}

.title {
  text-transform: uppercase;
  color: $white;
  font-size: 15px;
  line-height: 18px;
  font-weight: bold;
}

.dropdownWidthFixed {
  max-width: 280px;
}

//@todo Fix playoff stacking
.playoff {
  margin: 0 -12px;

  @media screen and (max-width: 768px)  {
    margin: 0;
  }
}